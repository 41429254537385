import { useDispatch } from 'react-redux'
import { openWindow } from 'models/window/actions'
import { modalNames } from 'features/modal/constants/names'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'

import c from './assets/sass/invite.module.scss'

export const Invite = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const stage = location.pathname.replace('/', '')

    const openInvite = () => {
        dispatch(openWindow(modalNames.INVITE))
    }

    return (
        <div
            className={cn(c.component, {
                [c.main]: stage === '',
                [c.friends]: stage === 'friends',
                [c.holders]: stage === 'holders',
                [c.gems]: stage.startsWith('gems'),
            })}
        >
            <button className={c.button} onClick={openInvite}>
                <span className={c.title}>Invite a Friend</span>
                <span className={c.yellow}>I need more EGGS</span>
            </button>
            <span className={c.info}>
                For every friend you receive <strong>50 eggs</strong>
            </span>
        </div>
    )
}
