import cn from 'classnames'
import { useWindowActions } from 'models/window'
import { Balance } from './balance'
import { Wallet } from './wallet'
import { useTonAddress } from '@tonconnect/ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { openWindow } from 'models/window/actions'
import { modalNames } from 'features/modal/constants/names'
import { isLoadingUserSelector, userSelector } from 'models/user/selectors'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'
import { ReactComponent as ArrowIcon } from './assets/images/arrow.svg'
import { ReactComponent as EggIcon } from './assets/images/egg.svg'
import { ReactComponent as BuyIcon } from './assets/images/buy.svg'
import { ReactComponent as DisconnectIcon } from './assets/images/disconnect-icon.svg'
import { ReactComponent as LinkIcon } from './assets/images/link-icon.svg'

import c from './assets/sass/settingsModal.module.scss'

export const SettingsModal = () => {
    const { closeWindow } = useWindowActions()
    const address = useTonAddress()
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const isLoading = useSelector(isLoadingUserSelector)

    const handleClickClose = () => {
        closeWindow()
    }

    const openDisconnect = () => {
        closeWindow()
        dispatch(openWindow(modalNames.DISCONNECT))
    }

    const openProfile = () => {
        closeWindow()
        dispatch(openWindow(modalNames.PROFILE))
    }

    const openWithdraw = () => {
        if (
            !isLoading &&
            user.withdraw_status === 0 &&
            address &&
            user.available_eggs > 0
        ) {
            closeWindow()
            dispatch(openWindow(modalNames.WITHDRAW))
        }
    }

    const getWithdrawInfo = () => {
        if (user.withdraw_status === 1) {
            return `Withdrawing ${user.available_eggs} EGGS...`
        }

        if (user.available_eggs > 0 && address) {
            return `Withdraw ${user.available_eggs} EGGS`
        }

        if (user.available_eggs > 0 && !address) {
            return `Set wallet & withdraw ${user.available_eggs} EGGS`
        }

        return 'No EGGS available for withdrawal'
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>Your wallet</span>
                <span className={c.subtitle}>
                    Send this link to your friend for more eggs
                </span>
            </div>
            <Wallet />
            <div className={c.balance}>
                <span className={c.balanceTitle}>Your balance</span>
                <Balance />
                <span className={c.balanceSubtitle}>
                    Your approximate balance
                </span>
            </div>
            <ul className={c.list}>
                <li className={cn(c.item, c.link)}>
                    <button className={c.itemButton} onClick={openProfile}>
                        <LinkIcon className={c.itemIcon} />
                        <div className={c.itemRow}>
                            <span className={c.itemTitle}>Link & Nickname</span>
                            <span className={c.itemSubtitle}>
                                It’s necessary for Gems Hunting mode
                            </span>
                        </div>
                        <ArrowIcon className={c.itemArrow} />
                    </button>
                </li>
                <li
                    className={cn(c.item, {
                        [c.disabled]: user.available_eggs > 0 && !address,
                    })}
                >
                    <button className={c.itemButton} onClick={openWithdraw}>
                        <EggIcon className={c.itemIcon} />
                        <span className={c.itemTitle}>{getWithdrawInfo()}</span>
                        <ArrowIcon className={c.itemArrow} />
                    </button>
                </li>
                <li className={c.item}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://dedust.io/swap/TON/EQBnjc9l5PJ0HnU6auEsqpo9N2WMnifSuFMCbAKipTbzKt48"
                        className={c.itemButton}
                    >
                        <BuyIcon className={c.itemIcon} />
                        <span className={c.itemTitle}>BUY or SELL</span>
                        <ArrowIcon className={c.itemArrow} />
                    </a>
                </li>
                {address && (
                    <li className={c.item}>
                        <button
                            className={c.itemButton}
                            onClick={openDisconnect}
                        >
                            <DisconnectIcon className={c.itemIcon} />
                            <span className={c.itemTitle}>
                                Disconnect wallet
                            </span>
                            <ArrowIcon className={c.itemArrow} />
                        </button>
                    </li>
                )}
            </ul>
        </div>
    )
}
