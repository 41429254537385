import { Record, List } from 'immutable'
import { FAIL, LOAD, SUCCESS } from './constants'

const AddressRecord = Record({
    address: null,
    name: null,
    profile: null,
    balance: null,
    telegram_id: null,
})

const HoldersRecord = Record({
    isLoading: false,
    addresses: List(),
})

const reducer = (state = new HoldersRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case LOAD:
            return state.set('isLoading', true)

        case SUCCESS:
            const addresses = List(
                payload.map((addr) =>
                    AddressRecord({
                        address: addr.address,
                        name: addr.name,
                        profile: addr.profile,
                        balance: addr.balance,
                        telegram_id: addr.telegram_id,
                    })
                )
            )
            return state.set('addresses', addresses).set('isLoading', false)

        case FAIL:
            return state.set('isLoading', false)

        default:
            return state
    }
}

export default reducer
