import { Record } from 'immutable'
import {
    GET_PROFILE_START,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR,
    GET_GEMS_ATTEMPTS_START,
    GET_GEMS_ATTEMPTS_SUCCESS,
    GET_GEMS_ATTEMPTS_ERROR,
    CHECK_GEMS_START,
    CHECK_GEMS_SUCCESS,
    CHECK_GEMS_ERROR,
    CLEAR,
    SET_CHECK_IS_DISABLED,
    SET_IS_ALREADY_OPENED,
} from './constants'

const GemsRecord = Record({
    isProfileLoading: false,
    isProfileLoaded: false,
    isGemsAttemptsLoading: false,
    isGemsAttemptsLoaded: false,
    isCheckLoading: false,
    isCheckLoaded: false,
    gemsAttempts: null,
    profile: null,
    gems: null,
    isAlreadyOpened: false,
    attemptReset: null,
})

const defaultState = new GemsRecord()

const reducer = (state = new GemsRecord(), action) => {
    const { type, payload } = action

    switch (type) {
        case GET_PROFILE_START:
            return state.set('isProfileLoading', true)

        case GET_PROFILE_SUCCESS:
            return state
                .set('profile', payload)
                .set('isProfileLoaded', true)
                .set('isProfileLoading', false)

        case GET_PROFILE_ERROR:
            return state
                .set('isProfileLoaded', false)
                .set('isProfileLoading', false)

        case GET_GEMS_ATTEMPTS_START:
            return state.set('isGemsAttemptsLoading', true)

        case GET_GEMS_ATTEMPTS_SUCCESS:
            return state
                .set('gemsAttempts', payload)
                .set('isAlreadyOpened', payload?.is_already_opened)
                .set('attemptReset', payload?.attempt_time_reset)
                .set('isGemsAttemptsLoaded', true)
                .set('isGemsAttemptsLoading', false)

        case GET_GEMS_ATTEMPTS_ERROR:
            return state
                .set('isGemsAttemptsLoaded', false)
                .set('isGemsAttemptsLoading', false)

        case CHECK_GEMS_START:
            return state.set('isCheckLoading', true)

        case CHECK_GEMS_SUCCESS:
            return state
                .set('gems', payload)
                .set('isCheckLoaded', true)
                .set('isCheckLoading', false)

        case CHECK_GEMS_ERROR:
            return state.set('isCheckLoading', false)

        case SET_CHECK_IS_DISABLED:
            return state.set('gemsAttempts', payload)

        case SET_IS_ALREADY_OPENED:
            return state
                .set('isAlreadyOpened', true)
                .set('attemptReset', payload?.attempt_time_reset)

        case CLEAR:
            return defaultState

        default:
            return state
    }
}

export default reducer
