export const moduleName = 'gems'

export const GET_PROFILE_START = `${moduleName}/GET_PROFILE_START`
export const GET_PROFILE_SUCCESS = `${moduleName}/GET_PROFILE_SUCCESS`
export const GET_PROFILE_ERROR = `${moduleName}/GET_PROFILE_ERROR`

export const GET_GEMS_ATTEMPTS_START = `${moduleName}/GET_GEMS_ATTEMPTS_START`
export const GET_GEMS_ATTEMPTS_SUCCESS = `${moduleName}/GET_GEMS_ATTEMPTS_SUCCESS`
export const GET_GEMS_ATTEMPTS_ERROR = `${moduleName}/GET_GEMS_ATTEMPTS_ERROR`

export const CHECK_GEMS_START = `${moduleName}/CHECK_GEMS_START`
export const CHECK_GEMS_SUCCESS = `${moduleName}/CHECK_GEMS_SUCCESS`
export const CHECK_GEMS_ERROR = `${moduleName}/CHECK_GEMS_ERROR`

export const CLEAR = `${moduleName}/CLEAR`

export const SET_CHECK_IS_DISABLED = `${moduleName}/SET_CHECK_IS_DISABLED`

export const SET_IS_ALREADY_OPENED = `${moduleName}/SET_IS_ALREADY_OPENED`
