import { toast } from 'react-toastify'
import cn from 'classnames'
import { setName } from 'models/user'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'models/user/selectors'

import c from './nickname.module.scss'

export const Nickname = () => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const [name, setNameInput] = useState(user.name || '')
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        setIsDisabled(name === user.name || name.trim() === '')
    }, [name, user.name])

    const handleNameChange = (e) => {
        const value = e.target.value

        setNameInput(value)
    }

    const handleNameSave = () => {
        if (name.length < 3) {
            toast.error('Name must be at least 3 characters long')
            return
        }

        if (name.length > 99) {
            toast.error('Name must be at less than 100 characters long')
            return
        }

        if (name !== user.name) {
            dispatch(setName(params, user.telegram_id, name))
        }
    }

    return (
        <div className={c.row}>
            <input
                type="text"
                className={c.field}
                value={name}
                onChange={handleNameChange}
                placeholder="Your nickname"
            />
            <button
                className={cn(c.button, {
                    [c.disabled]: isDisabled,
                })}
                onClick={handleNameSave}
            >
                Save
            </button>
        </div>
    )
}
