import { toast } from 'react-toastify'
import { setProfile } from 'models/user'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'models/user/selectors'

import c from './profile.module.scss'

export const Profile = () => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }
    const [profile, setProfileInput] = useState(user.profile || '')
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setIsDisabled(profile === user.profile || profile.trim() === '')
    }, [profile, user.profile])

    const handleProfileChange = (e) => {
        const inputValue = e.target.value
        const sanitizedValue = inputValue.startsWith('@')
            ? inputValue.slice(1)
            : inputValue
        setProfileInput(sanitizedValue)
    }

    const validateProfile = (profile) => {
        const regex = /^[a-zA-Z0-9._]{5,32}$/
        return regex.test(profile)
    }

    const handleProfileSave = () => {
        if (profile.length < 5) {
            toast.error(
                'Telegram profile link must be at least 3 characters long'
            )
            return
        }

        const profileWithoutAt = profile.startsWith('@')
            ? profile.slice(1)
            : profile

        if (!validateProfile(profileWithoutAt)) {
            toast.error(
                'Invalid Telegram profile link. Must contain 5 to 32 characters (letters, digits, ".", or "_").'
            )
            return
        }
        if (profileWithoutAt !== user.profile) {
            dispatch(setProfile(params, user.telegram_id, profileWithoutAt))
        }
    }

    return (
        <div className={c.row}>
            <input
                type="text"
                className={c.field}
                value={profile}
                onChange={handleProfileChange}
                placeholder="Your @profile"
            />
            <button
                className={cn(c.button, {
                    [c.disabled]: isDisabled,
                })}
                onClick={handleProfileSave}
            >
                Save
            </button>
        </div>
    )
}
