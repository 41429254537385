import { Egg } from 'features/egg'
import { Mcap } from 'features/main/components/mcap'
import { Navigation } from 'features/navigation'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, getUser, updateWalletAddress } from 'models/user/actions'
import { isLoadingUserSelector, userSelector } from 'models/user/selectors'
import { useTonConnectUI } from '@tonconnect/ui-react'

import c from './assets/sass/page.module.scss'

const decodeTelegramData = (initData) => {
    const decodedData = new URLSearchParams(initData)
    const result = {}

    decodedData.forEach((value, key) => {
        if (key === 'user') {
            result[key] = JSON.parse(decodeURIComponent(value))
        } else {
            result[key] = decodeURIComponent(value)
        }
    })

    return result
}

const MainPage = () => {
    const dispatch = useDispatch()
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }
    const user = useSelector(userSelector)
    const isLoadingUser = useSelector(isLoadingUserSelector)
    const [tonConnectUI] = useTonConnectUI()
    const decodedData = useMemo(() => decodeTelegramData(initData), [initData])

    useEffect(() => {
        if (decodedData?.user?.id) {
            dispatch(getUser(params, decodedData.user.id))
        }
    }, [dispatch, decodedData])

    useEffect(() => {
        if (telegramData && isLoadingUser && !user.telegram_id) {
            const userData = {
                name: decodedData.user.first_name,
                telegram_id: decodedData.user.id,
                profile: '',
                wallet_address: '',
                has_premium: decodedData.user.is_premium,
                invited_by:
                    decodedData.start_param !== decodedData.user.id
                        ? decodedData.start_param
                        : '',
            }

            dispatch(createUser(params, userData))
        }
    }, [dispatch, telegramData, user, decodedData, params, isLoadingUser])

    useEffect(() => {
        tonConnectUI.onStatusChange((walletInfo) => {
            if (
                walletInfo?.connectItems?.tonProof &&
                user.telegram_id &&
                params
            ) {
                const walletData = {
                    proof: walletInfo.connectItems.tonProof.proof,
                    account: walletInfo.account,
                }

                dispatch(
                    updateWalletAddress(params, user.telegram_id, walletData)
                )
            }
        })
    }, [tonConnectUI, user, params])

    useEffect(() => {
        if (tonConnectUI) {
            tonConnectUI.setConnectRequestParameters({
                state: 'ready',
                value: { tonProof: 'biggeg001928493819di883=' },
            })
        }
    }, [tonConnectUI])

    return (
        <div className={c.component}>
            <div className={c.chart} />
            <Mcap />
            <Egg type="main" />
            <div className={c.bottom}>
                <Navigation />
            </div>
        </div>
    )
}

export default MainPage
