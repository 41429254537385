import { Route, Routes, useParams } from 'react-router-dom'
import { Layout } from 'features/layout'
import MainPage from 'pages/main/page'
import FriendsPage from 'pages/friends/page'
import HoldersPage from 'pages/holders/page'
import WelcomePage from 'pages/welcome/page'
import GemsPage from './gems/page'
import { routes } from 'core/routes/paths'

const GetGems = () => {
    const { profileId } = useParams()

    return <GemsPage profileId={profileId} />
}

export const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path={routes.main} element={<MainPage />} />
                <Route path={routes.friends} element={<FriendsPage />} />
                <Route path={routes.holders} element={<HoldersPage />} />
                <Route path={routes.welcome} element={<WelcomePage />} />
                <Route path={routes.gems()} element={<GetGems />} />
            </Route>
        </Routes>
    )
}
