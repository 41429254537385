import {
    GET_PROFILE_START,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR,
    GET_GEMS_ATTEMPTS_START,
    GET_GEMS_ATTEMPTS_SUCCESS,
    GET_GEMS_ATTEMPTS_ERROR,
    CHECK_GEMS_START,
    CHECK_GEMS_SUCCESS,
    CHECK_GEMS_ERROR,
    CLEAR,
    SET_CHECK_IS_DISABLED,
    SET_IS_ALREADY_OPENED,
} from './constants'
import { gemsApi } from 'api/gems'

export const getProfile = (initData, telegram_id) => (dispatch) => {
    dispatch({ type: GET_PROFILE_START })

    gemsApi
        .getProfile(initData, telegram_id)
        .then((response) =>
            dispatch({
                type: GET_PROFILE_SUCCESS,
                payload: response.data,
            })
        )
        .catch(() => {
            dispatch({ type: GET_PROFILE_ERROR })
        })
}

export const getGemsAttempts =
    (initData, telegram_id, target_telegram_id) => (dispatch) => {
        dispatch({ type: GET_GEMS_ATTEMPTS_START })

        gemsApi
            .getGemsAttempts(initData, telegram_id, target_telegram_id)
            .then((response) =>
                dispatch({
                    type: GET_GEMS_ATTEMPTS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch(() => {
                dispatch({ type: GET_GEMS_ATTEMPTS_ERROR })
            })
    }

export const checkGems =
    (initData, telegram_id, target_telegram_id) => (dispatch) => {
        dispatch({ type: CHECK_GEMS_START })

        gemsApi
            .checkGems(initData, telegram_id, target_telegram_id)
            .then((response) =>
                dispatch({
                    type: CHECK_GEMS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch(() => {
                dispatch({ type: CHECK_GEMS_ERROR })
            })
    }

export const clear = () => ({
    type: CLEAR,
})

export const setCheckIsDisabled = (payload) => ({
    type: SET_CHECK_IS_DISABLED,
    payload: payload,
})

export const setIsAlreadyOpened = (payload) => ({
    type: SET_IS_ALREADY_OPENED,
    payload: payload,
})
