import { useWindowActions } from 'models/window'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'
import { ReactComponent as TelegramIcon } from './assets/images/telegram.svg'
import { ReactComponent as XIcon } from './assets/images/x.svg'
import { ReactComponent as WebIcon } from './assets/images/web.svg'

import c from './assets/sass/faqModal.module.scss'

export const FaqModal = () => {
    const { closeWindow } = useWindowActions()

    const handleClickClose = () => {
        closeWindow()
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>FAQ</span>
                <span className={c.subtitle}>Questions and answers</span>
            </div>
            <ul className={c.list}>
                <li className={c.item}>
                    <span className={c.itemTitle}>What does balance mean?</span>
                    <p className={c.text}>
                        Your balance consist of wallet balance + earnings for
                        inviting friends
                    </p>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>How to invite friends?</span>
                    <p className={c.text}>
                        You can invite friends using button «Invite a Friend».
                        For each friend who registers, you will earn 1 EGG.
                    </p>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>What are EGGS used for?</span>
                    <p className={c.text}>
                        EGGS are a meme cryptocurrency. Collect them for fun and
                        claim your profit!
                    </p>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>
                        When can I see my referral statistic?
                    </span>
                    <p className={c.text}>
                        You can check your referral earnings anytime in your
                        profile «Friends» section.
                    </p>
                </li>
            </ul>
            <ul className={c.socials}>
                <li className={c.socialsItem}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/worldeggio"
                        className={c.socialsButton}
                    >
                        <TelegramIcon />
                    </a>
                </li>
                <li className={c.socialsItem}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://x.com/worldeggio"
                        className={c.socialsButton}
                    >
                        <XIcon />
                    </a>
                </li>
                <li className={c.socialsItem}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://worldegg.io/"
                        className={c.socialsButton}
                    >
                        <WebIcon />
                    </a>
                </li>
            </ul>
        </div>
    )
}
