import { Balance } from '../settings-modal/balance'
import { useWindowActions } from 'models/window'
import { useSelector } from 'react-redux'
import { balanceSelector, userSelector } from 'models/user/selectors'
import { formatNumbers } from 'core/helpers/format-numbers'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/balanceModal.module.scss'

export const BalanceModal = () => {
    const { closeWindow } = useWindowActions()
    const user = useSelector(userSelector)
    const balance = useSelector(balanceSelector)
    const totalBalance = parseFloat((balance / 1000000000).toFixed(2))

    const handleClickClose = () => {
        closeWindow()
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.balanceTitle}>Your balance</span>
                <Balance />
                <span className={c.balanceSubtitle}>TOTAL BALANCE</span>
            </div>
            <ul className={c.list}>
                <li className={c.item}>
                    <span className={c.itemTitle}>Wallet balance</span>
                    <span className={c.counter}>
                        {formatNumbers(totalBalance || 0)} EGGS
                    </span>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>
                        Earnings from invitation
                    </span>
                    <span className={c.counter}>
                        {formatNumbers(user.earned_eggs || 0)} EGGS
                    </span>
                </li>
                <li className={c.item}>
                    <span className={c.itemTitle}>Gems loot reward</span>
                    <span className={c.counter}>
                        {formatNumbers(user.earned_gems || 0)} EGGS
                    </span>
                </li>
            </ul>
        </div>
    )
}
