import { useEffect } from 'react'
import { io } from 'socket.io-client'
import { useDispatch } from 'react-redux'
import { setCheckIsDisabled, setIsAlreadyOpened } from '../../models/gems'

const Broadcast = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const socket = io('https://wr-egg.com', {
            path: '/ws',
            transports: ['websocket'],
        })

        socket.on('gemsDailyLimitReached', (data) => {
            console.log('Gems Limit')
            dispatch(setCheckIsDisabled(data))
        })

        socket.on('gemsAlreadyOpened', (data) => {
            console.log('Already opened')
            dispatch(setIsAlreadyOpened(data))
        })

        return () => {
            socket.disconnect()
        }
    }, [])

    return null
}

export default Broadcast
