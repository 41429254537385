import { useRef, useState } from 'react'
import cn from 'classnames'
import { useWindowActions } from 'models/window'
import { toast } from 'react-toastify'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'
import { ReactComponent as CopyIcon } from './assets/images/copy.svg'
import { ReactComponent as ShareIcon } from './assets/images/share.svg'

import c from './assets/sass/inviteModal.module.scss'

export const InviteModal = () => {
    const { closeWindow } = useWindowActions()
    const [isCopied, setIsCopied] = useState(false)
    const inviteLinkRef = useRef(null)
    const telegramData = window.Telegram.WebApp

    const handleClickClose = () => {
        closeWindow()
    }

    const handleCopy = () => {
        const link = inviteLinkRef.current.textContent
        navigator.clipboard.writeText(link).then(() => {
            setIsCopied(true)
        })

        toast.success('Copied!')
    }

    const handleShare = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink(
                `https://t.me/share/url?url=https://t.me/worldegg_bot/egg?startapp=${telegramData.initDataUnsafe?.user?.id}`
            )
        } else {
            toast.warning('Please copy the link instead of sharing!')
        }
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>Invite link</span>
                <span className={c.subtitle}>
                    Send this link to your friend for more eggs
                </span>
                <p className={c.reward}>
                    50 EGGS for friend <br />
                    250 EGGS for friend with TG Premium
                </p>
            </div>
            <div className={c.form}>
                <div className={c.field} ref={inviteLinkRef}>
                    <span>
                        {`https://t.me/worldegg_bot/egg?startapp=${telegramData.initDataUnsafe?.user?.id}`}
                    </span>
                    <button
                        className={cn(c.copy, { [c.copied]: isCopied })}
                        onClick={handleCopy}
                    >
                        <CopyIcon className={c.copyIcon} />
                    </button>
                </div>
                <button className={c.button} onClick={handleShare}>
                    <span>Share</span>
                    <ShareIcon className={c.shareIcon} />
                </button>
            </div>
        </div>
    )
}
