import { createSelector } from 'reselect'
import { moduleName } from './constants'

const state = (state) => state[moduleName]

export const isProfileLoadingSelector = createSelector(
    state,
    ({ isProfileLoading }) => isProfileLoading
)

export const isProfileLoadedSelector = createSelector(
    state,
    ({ isProfileLoaded }) => isProfileLoaded
)

export const profileSelector = createSelector(state, ({ profile }) => profile)

export const isCheckLoadingSelector = createSelector(
    state,
    ({ isCheckLoading }) => isCheckLoading
)

export const isCheckLoadedSelector = createSelector(
    state,
    ({ isCheckLoaded }) => isCheckLoaded
)

export const gemsSelector = createSelector(state, ({ gems }) => gems)

export const isGemsAttemptsLoadedSelector = createSelector(
    state,
    ({ isGemsAttemptsLoaded }) => isGemsAttemptsLoaded
)

export const gemsAttemptsSelector = createSelector(
    state,
    ({ gemsAttempts }) => gemsAttempts
)

export const isAlreadyOpenedSelector = createSelector(
    state,
    ({ isAlreadyOpened }) => isAlreadyOpened
)

export const attemptResetSelector = createSelector(
    state,
    ({ attemptReset }) => attemptReset
)
