import axios from 'core/http'

const root = 'https://wr-egg.com/api/users'

export const userApi = {
    getUser: (initData, telegram_id) =>
        axios.post(`${root}/${telegram_id}`, { initData }),

    createUser: (initData, userData) =>
        axios.post(`${root}/create`, { initData, userData }),

    getBalance: (accountId) =>
        axios.get(
            `https://tonapi.io/v2/accounts/${accountId}/jettons/0%3A678dcf65e4f2741e753a6ae12caa9a3d37658c9e27d2b853026c02a2a536f32a?currencies=usd&supported_extensions=custom_payload`
        ),

    getFriends: (initData, telegram_id) =>
        axios.post(`${root}/${telegram_id}/friends`, { initData }),

    requestWithdraw: (initData, telegram_id, wallet_address) =>
        axios.put(`${root}/${telegram_id}/requestWithdraw`, {
            initData,
            wallet_address,
        }),

    setProfile: (initData, telegram_id, profile) =>
        axios.put(`${root}/${telegram_id}/setProfile`, { initData, profile }),

    setName: (initData, telegram_id, name) =>
        axios.put(`${root}/${telegram_id}/setName`, { initData, name }),

    setDescription: (initData, telegram_id, description) =>
        axios.put(`${root}/${telegram_id}/setDescription`, {
            initData,
            description,
        }),

    updateWalletAddress: (initData, telegram_id, walletData) =>
        axios.put(`${root}/${telegram_id}/updateWalletAddress`, {
            initData,
            walletData,
        }),

    disconnectWallet: (initData, telegram_id) =>
        axios.post(`${root}/${telegram_id}/disconnectWallet`, { initData }),

    uploadAvatar: (telegram_id, avatarFile) => {
        const formData = new FormData()
        formData.append('avatar', avatarFile)

        return axios.post(`${root}/${telegram_id}/uploadAvatar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
}
