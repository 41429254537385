import { useSelector } from 'react-redux'
import {
    isProfileLoadingSelector,
    isProfileLoadedSelector,
    profileSelector,
} from 'models/gems/selectors'
import Skeleton from 'react-loading-skeleton'

import c from './assets/sass/profile.module.scss'

export const Profile = () => {
    const isLoading = useSelector(isProfileLoadingSelector)
    const isLoaded = useSelector(isProfileLoadedSelector)
    const profile = useSelector(profileSelector)

    const getName = () => {
        if (isLoading) {
            return <Skeleton width="100px" baseColor="#4F4A4A" />
        }

        if (isLoaded && profile.name) {
            return profile.name
        }

        if (isLoaded && profile && !profile.name) {
            return 'Some EGG 🥚 holder'
        }
    }

    const getDescription = () => {
        if (isLoading) {
            return <Skeleton width="200px" baseColor="#4F4A4A" />
        }

        if (isLoaded && profile.description) {
            return profile.description
        }

        if (isLoaded && profile && !profile.description) {
            return 'Description will be later... may be'
        }
    }

    const getButton = () => {
        if (isLoading) {
            return (
                <div className={c.goButton}>
                    <span className={c.buttonTitle}>
                        <Skeleton width="130px" baseColor="#4F4A4A" />
                    </span>
                </div>
            )
        }

        if (isLoaded && profile.profile) {
            return (
                <a
                    href={`https://t.me/${profile.profile}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={c.goButton}
                >
                    <span className={c.buttonTitle}>Go to Telegram</span>
                </a>
            )
        }

        return null
    }

    const getAvatar = () => {
        if (isLoading) {
            return <Skeleton width="128px" height="128px" baseColor="#4F4A4A" />
        }

        if (isLoaded && profile.profile_image) {
            return (
                <img
                    src={`https://s3.wr-egg.com/avatars/${profile.profile_image}`}
                    alt={profile.name}
                />
            )
        }

        return null
    }

    return (
        <div className={c.component}>
            <div className={c.avatar}>{getAvatar()}</div>
            <span className={c.title}>{getName()}</span>
            <p className={c.description}>{getDescription()}</p>
            {getButton()}
        </div>
    )
}
