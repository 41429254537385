import cn from 'classnames'
import { Link } from 'react-router-dom'
import { formatNumbers } from 'core/helpers/format-numbers'
import { routes } from 'core/routes/paths'

import c from './assets/sass/item.module.scss'

export const Item = ({ friend }) => {
    return (
        <li className={c.component}>
            {friend.referral_profile ? (
                <Link to={routes.gems(friend.referral_id)} className={c.row}>
                    <span className={cn(c.name, c.withLink)}>
                        {friend.referral_name || friend.referral_id}
                    </span>
                    <span className={c.eggs}>
                        +{formatNumbers(friend.referral_eggs)}
                    </span>
                </Link>
            ) : (
                <div className={c.row}>
                    <span className={c.name}>
                        {friend.referral_name || friend.referral_id}
                    </span>
                    <span className={c.eggs}>
                        +{formatNumbers(friend.referral_eggs)}
                    </span>
                </div>
            )}
        </li>
    )
}
