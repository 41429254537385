import cn from 'classnames'
import { useWindowActions } from 'models/window'
import { useTonAddress } from '@tonconnect/ui-react'
import { useSelector } from 'react-redux'
import { userSelector } from 'models/user/selectors'
import { useState } from 'react'
import { userApi } from 'api/user'
import { toast } from 'react-toastify'
import { formatNumbers } from '../../core/helpers/format-numbers'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/withdrawModal.module.scss'

export const WithdrawModal = () => {
    const { closeWindow } = useWindowActions()
    const [isConfirmed, setIsConfirmed] = useState(false)
    const address = useTonAddress()
    const user = useSelector(userSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }

    const handleClickClose = () => {
        closeWindow()
    }

    const handleWithdraw = async () => {
        if (user.available_eggs > 100) {
            try {
                await userApi.requestWithdraw(params, user.telegram_id, address)
                toast.success(
                    'The withdrawal has been requested. Please wait for the EGGS to be credited!'
                )
                setIsConfirmed(true)
            } catch (error) {
                toast.error(error)
            }
        } else {
            toast.error('Not enough EGGS!')
        }
    }

    const getSubtitle = () => {
        if (user.available_eggs > 100) {
            return (
                <span className={c.subtitle}>
                    You are going to withdraw{' '}
                    <strong>{formatNumbers(user.available_eggs)} EGGS</strong>{' '}
                    to wallet. System fee is <strong>100 EGGS</strong>
                </span>
            )
        }

        return (
            <span className={c.subtitle}>
                You have{' '}
                <strong>{formatNumbers(user.available_eggs)} EGGS</strong>, but
                system fee is <strong>100 EGGS</strong>. Invite more friends!
            </span>
        )
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>Withdraw</span>
                {getSubtitle()}
            </div>
            <div className={c.form}>
                <div className={c.field}>
                    <span>{address ? address : 'No wallet found!'}</span>
                </div>
                <button
                    className={cn(c.button, {
                        [c.confirmed]: isConfirmed,
                        [c.notEnough]: user.available_eggs <= 100,
                    })}
                    onClick={handleWithdraw}
                    disabled={isConfirmed || user.available_eggs <= 100}
                >
                    {isConfirmed ? 'Confirmed!' : 'Confirm'}
                </button>
            </div>
        </div>
    )
}
