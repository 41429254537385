import React, { useState } from 'react'
import cn from 'classnames'
import { toast } from 'react-toastify'
import { userApi } from 'api/user'
import { useSelector } from 'react-redux'
import { userSelector } from 'models/user/selectors'
import { Ellipsis } from 'ui'

import c from './avatar.module.scss'

export const Avatar = () => {
    const user = useSelector(userSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }
    const [avatarFile, setAvatarFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const hasAvatar = user?.profile_image

    console.log(user.toJS())

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        setDownloading(true)

        if (file) {
            setAvatarFile(file)
            setDownloading(false)
        }
    }

    const handleSave = async () => {
        if (!avatarFile) {
            toast.error('Please select an avatar to upload')
            return
        }

        if (!uploading) {
            setUploading(true)

            try {
                const response = await userApi.uploadAvatar(
                    user.telegram_id,
                    avatarFile
                )
                toast.success('Avatar uploaded successfully')
                setUploading(false)
            } catch (err) {
                setUploading(false)
                toast.error('Failed to upload avatar')
            }
        }
    }

    const getField = () => {
        if (uploading) {
            return 'Uploading...'
        }

        if (downloading) {
            return 'Downloading...'
        }

        if (hasAvatar) {
            return 'This is your avatar'
        }

        return 'Select your avatar'
    }

    return (
        <div className={c.row}>
            <div
                className={cn(c.preview, {
                    [c.hasAvatar]: hasAvatar,
                })}
            >
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className={c.input}
                />
                {user.profile_image && (
                    <img
                        className={c.currentImage}
                        src={`https://s3.wr-egg.com/avatars/${user.profile_image}`}
                        alt={user.name}
                    />
                )}
            </div>
            <span className={c.field}>{getField()}</span>
            <button
                className={cn(c.button, {
                    [c.disabled]:
                        (hasAvatar || uploading || downloading) && !avatarFile,
                    [c.uploading]: uploading,
                })}
                onClick={handleSave}
            >
                Save
                <div className={c.loaderWrapper}>
                    {uploading && <Ellipsis className={c.loader} />}
                </div>
            </button>
        </div>
    )
}
