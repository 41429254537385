import { toast } from 'react-toastify'
import cn from 'classnames'
import { setDescription } from 'models/user'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { userSelector } from 'models/user/selectors'

import c from './description.module.scss'

export const Description = () => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }
    const [description, setDescriptionInput] = useState(
        user.profile_description || ''
    )
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        setIsDisabled(
            description === user.profile_description ||
                description.trim() === ''
        )
    }, [description, user.profile_description])

    const handleDescriptionSave = () => {
        if (description.length > 99) {
            toast.error('Description must be less than 100 characters')
            return
        }

        if (description !== user.profile_description) {
            dispatch(setDescription(params, user.telegram_id, description))
        }
    }

    const handleDescriptionChange = (e) => {
        const inputValue = e.target.value
        setDescriptionInput(inputValue)
    }

    return (
        <div className={c.row}>
            <input
                type="text"
                className={c.field}
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Short description"
            />
            <button
                className={cn(c.button, {
                    [c.disabled]: isDisabled,
                })}
                onClick={handleDescriptionSave}
            >
                Save
            </button>
        </div>
    )
}
