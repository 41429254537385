import cn from 'classnames'

import c from './ellipsis.module.scss'

export const Ellipsis = ({ className = null }) => (
    <div className={cn(c.loader, className)}>
        <div />
        <div />
        <div />
        <div />
    </div>
)
