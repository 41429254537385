import cn from 'classnames'
import { formatNumbers } from 'core/helpers/format-numbers'
import { Link } from 'react-router-dom'
import { routes } from 'core/routes/paths'

import c from './assets/sass/item.module.scss'

export const TableItem = ({ type, holder, inviter }) => {
    const getHolder = () => {
        if (holder.profile) {
            return (
                <li className={c.component}>
                    <Link
                        to={routes.gems(holder.telegram_id)}
                        className={c.row}
                    >
                        <span className={cn(c.name, c.withLink)}>
                            {holder.name || holder.profile}
                        </span>
                        <span className={c.eggs}>
                            {formatNumbers(holder.balance)}
                        </span>
                    </Link>
                </li>
            )
        }

        return (
            <li className={c.component}>
                <div className={c.row}>
                    <span className={c.name}>{holder.name}</span>
                    <span className={c.eggs}>
                        {formatNumbers(holder.balance)}
                    </span>
                </div>
            </li>
        )
    }

    const getInviter = () => {
        if (inviter.profile) {
            return (
                <li className={c.component}>
                    <Link
                        to={routes.gems(inviter.telegram_id)}
                        className={cn(c.row, c.withUsers)}
                    >
                        <span className={cn(c.name, c.withLink)}>
                            {inviter.name || inviter.telegram_id}
                        </span>
                        <span className={c.users}>
                            {inviter.referral_count}
                        </span>
                        <span className={c.eggs}>
                            {formatNumbers(parseFloat(inviter.earned_eggs))}
                        </span>
                    </Link>
                </li>
            )
        }

        return (
            <li className={c.component}>
                <div className={cn(c.row, c.withUsers)}>
                    <span className={c.name}>
                        {inviter.name || inviter.telegram_id}
                    </span>
                    <span className={c.users}>{inviter.referral_count}</span>
                    <span className={c.eggs}>
                        {formatNumbers(parseFloat(inviter.earned_eggs))}
                    </span>
                </div>
            </li>
        )
    }

    const getItem = () => {
        if (type === 'holder') {
            return getHolder()
        }

        if (type === 'inviter') {
            return getInviter()
        }

        return null
    }

    return getItem()
}
