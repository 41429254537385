import axios from 'core/http'

const root = 'https://wr-egg.com/api/gems'

export const gemsApi = {
    getProfile: (initData, profile_id) =>
        axios.post(`${root}/get-profile/${profile_id}`, { initData }),

    getGemsAttempts: (initData, telegram_id, target_telegram_id) =>
        axios.post(`${root}/get-gems-attempts/${telegram_id}`, {
            initData,
            target_telegram_id,
        }),

    checkGems: (initData, telegram_id, target_telegram_id) =>
        axios.post(`${root}/check-gems/${telegram_id}`, {
            initData,
            target_telegram_id,
        }),
}
