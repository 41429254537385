import { useState, useRef, useEffect } from 'react'
import { QRCodeSVG } from 'qrcode.react'

import c from './assets/sass/page.module.scss'

const WelcomePage = () => {
    const [lines, setLines] = useState([])
    const lastPosition = useRef({ x: null, y: null })
    const leftEyeRef = useRef(null)
    const rightEyeRef = useRef(null)

    useEffect(() => {
        const updateAppHeight = () => {
            document.documentElement.style.setProperty(
                '--welcome-height',
                `${window.innerHeight}px`
            )
        }

        updateAppHeight()
        window.addEventListener('resize', updateAppHeight)

        return () => {
            window.removeEventListener('resize', updateAppHeight)
        }
    }, [])

    const handleMouseMove = (event) => {
        const newX = event.clientX + 13
        const newY = event.clientY + 13

        if (
            lastPosition.current.x !== null &&
            lastPosition.current.y !== null
        ) {
            const newLine = {
                x1: lastPosition.current.x,
                y1: lastPosition.current.y,
                x2: newX,
                y2: newY,
            }
            setLines((prevLines) => {
                const updatedLines = [...prevLines, newLine]

                if (updatedLines.length > 100) {
                    updatedLines.shift()
                }
                return updatedLines
            })
        }

        lastPosition.current = { x: newX, y: newY }

        moveEyes(event.clientX, event.clientY)
    }

    const moveEyes = (mouseX, mouseY) => {
        const leftEye = leftEyeRef.current.getBoundingClientRect()
        const rightEye = rightEyeRef.current.getBoundingClientRect()

        moveEye(mouseX, mouseY, leftEye, leftEyeRef)
        moveEye(mouseX, mouseY, rightEye, rightEyeRef)
    }

    const moveEye = (mouseX, mouseY, eyeRect, eyeRef) => {
        const eyeCenterX = eyeRect.left + eyeRect.width / 2
        const eyeCenterY = eyeRect.top + eyeRect.height / 2

        const deltaX = mouseX - eyeCenterX
        const deltaY = mouseY - eyeCenterY
        const angle = Math.atan2(deltaY, deltaX)

        const distance = Math.min(10, Math.hypot(deltaX, deltaY))
        const offsetX = distance * Math.cos(angle)
        const offsetY = distance * Math.sin(angle)

        eyeRef.current.style.transform = `translate(${offsetX}px, ${offsetY}px)`
    }

    const handleMouseLeave = () => {
        lastPosition.current = { x: null, y: null }
    }

    return (
        <div
            className={c.component}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        >
            <svg className={c.rocketTail}>
                {lines.map((line, index) => (
                    <line
                        key={index}
                        x1={line.x1}
                        y1={line.y1}
                        x2={line.x2}
                        y2={line.y2}
                        stroke="white"
                        strokeWidth="2"
                    />
                ))}
            </svg>
            <span className={c.title}>Touch the EGGS!</span>
            <QRCodeSVG
                className={c.code}
                value="https://t.me/worldegg_bot/egg"
            />
            <span className={c.subtitle}>
                Scan this code and smash some EGGS in our Telegram app!
            </span>
            <div className={c.egg}>
                <div className={c.eyes}>
                    <div className={c.eye}>
                        <div className={c.eyeInner} ref={leftEyeRef} />
                    </div>
                    <div className={c.eye}>
                        <div className={c.eyeInner} ref={rightEyeRef} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomePage
