import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'

import c from './assets/sass/userTimer.module.scss'

export const UserTimer = ({ resetDate }) => {
    const endTime = new Date(resetDate).getTime()

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Try your luck!</span>
        }

        return (
            <span>
                Come back in {zeroPad(hours)}:{zeroPad(minutes)}:
                {zeroPad(seconds)}
            </span>
        )
    }

    return (
        <div className={c.component}>
            <Countdown date={endTime} renderer={renderer} />
        </div>
    )
}
