import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { userSelector } from '../../models/user/selectors'
import { getGemsAttempts, getProfile, clear } from 'models/gems'
import { CheckGems } from 'features/gems/check-gems'
import { Profile } from 'features/gems/profile'

import c from './assets/sass/gems.module.scss'

const GemsPage = ({ profileId }) => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }

    useEffect(() => {
        dispatch(getProfile(params, profileId))
        dispatch(getGemsAttempts(params, user.telegram_id, profileId))

        return () => {
            dispatch(clear())
        }
    }, [dispatch])

    return (
        <div className={c.component}>
            <div className={c.row}>
                <Profile />
                <CheckGems targetId={profileId} />
            </div>
        </div>
    )
}

export default GemsPage
