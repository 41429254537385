import { useWindowActions } from 'models/window'
import { Profile } from './profile'
import { Nickname } from './nickname'
import { Description } from './description'
import { Avatar } from './avatar'

import { ReactComponent as CloseIcon } from './assets/images/close.svg'

import c from './assets/sass/profileModal.module.scss'

export const ProfileModal = () => {
    const { closeWindow } = useWindowActions()

    const handleClickClose = () => {
        closeWindow()
    }

    return (
        <div className={c.component}>
            <button className={c.close} onClick={handleClickClose}>
                <CloseIcon />
            </button>
            <div className={c.top}>
                <span className={c.title}>Link & Nickname</span>
                <span className={c.subtitle}>
                    Add Telegram profile link & custom nickname
                </span>
            </div>
            <div className={c.form}>
                <Profile />
                <Nickname />
                <Description />
                <Avatar />
            </div>
        </div>
    )
}
